import React from "react"
import { Link } from "gatsby"
import Button from "../components/common/Button"
import Layout from "../components/common/Layout"
import SEO from "../components/common/SEO"

function NotFound() {
  return (
    <Layout>
      <SEO title="Not Found" />
      <main
        css={`
          margin: 0 auto;
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
        `}
      >
        <h1>Oops!</h1>
        <Button as={Link} to="/">
          Go Home!
        </Button>
      </main>
    </Layout>
  )
}

export default NotFound
